<template>
	<div>
		<!-- profile update error modal -->
		<modal v-model="hasProfileUpdatingError">
			<template #body>
				<div class="d-flex flex-column px-2 pb-5 mx-auto">
					<h6 class="text-center text-h6 font-weight-bold mb-4">
						{{ profileUpdateErrorMessage }}
					</h6>

					<div class="d-flex align-center justify-center">
						<v-btn
							large
							rounded
							type="button"
							color="primary"
							class="font-weight-bold"
							@click="hasProfileUpdatingError = false"
							>Ok</v-btn
						>
					</div>
				</div>
			</template>
		</modal>

		<!-- profile updated modal -->
		<modal v-model="isProfileUpdated">
			<template #body>
				<div class="d-flex flex-column px-5 pb-5 mx-auto">
					<h6 class="text--secondary text-center text-h6 font-weight-bold mb-4">
						Your profile updated successfully!!
					</h6>

					<div class="d-flex align-center justify-center">
						<v-btn
							large
							rounded
							type="button"
							color="primary"
							class="font-weight-bold"
							@click="isProfileUpdated = false"
							>Ok</v-btn
						>
					</div>
				</div>
			</template>
		</modal>

		<!-- card -->
		<v-card flat elevation="0" class="min-height-screen">
			<v-toolbar flat class="border-b-2 border--secondary mb-8 px-0 py-3 !h-85px">
				<v-toolbar-title>
					<a
						:href="`${baseUrl}/me?token=${authToken}`"
						class="text-decoration-none mr-2 d-inline-block"
					>
						<v-icon color="primary">mdi-arrow-left</v-icon>
					</a>

					<strong> Edit Profile </strong>
				</v-toolbar-title>
			</v-toolbar>

			<div class="px-3 pb-8 h-full w-full">
				<div v-if="isLoading" class="h-full d-flex justify-center align-center">
					<loader />
				</div>
				<v-form v-else ref="updateProfileFormRef" @submit.prevent="handleUpdateUserProfile">
					<v-row>
						<v-col cols="6" class="py-0">
							<label
								for="firstname"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>First Name</label
							>
							<v-text-field
								id="firstname"
								:rules="[requiredRule]"
								v-model="user.firstName"
								placeholder="Enter First Name"
								class="rounded-small"
								outlined
							/>
						</v-col>
						<v-col cols="6" class="py-0">
							<label
								for="lastname"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Last Name</label
							>
							<v-text-field
								id="lastname"
								outlined
								v-model="user.lastName"
								placeholder="Enter Last Name"
								class="rounded-small"
							/>
						</v-col>
						<v-col cols="12" class="py-0">
							<label
								for="email"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Email Address</label
							>
							<v-text-field
								id="email"
								outlined
								:rules="[requiredRule, emailRule]"
								v-model="user.emailId"
								class="rounded-small"
								placeholder="Enter Email Address"
							/>
						</v-col>
						<v-col cols="12" class="py-0">
							<div>
								<label
									for="phone-number"
									class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
									>Phone Number</label
								>
								<v-text-field
									id="phone-number"
									outlined
									:rules="[requiredRule, numberRule, mobileNoRule]"
									v-model="user.mobileNo"
									class="rounded-small"
									placeholder="Enter Phone Number"
								/>
							</div>
						</v-col>
						<v-col cols="12" class="py-0">
							<div>
								<label
									for="gender"
									class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
									>Gender</label
								>
								<v-select
									id="gender"
									outlined
									v-model="user.gender"
									placeholder="Select Gender"
									class="rounded-small"
									:items="genderOptions"
								/>
							</div>
						</v-col>
						<v-col cols="12" class="py-0">
							<label
								for="post-code"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Post Code</label
							>
							<v-text-field
								id="post-code"
								outlined
								:rules="[requiredRule]"
								v-model="user.zipCode"
								class="rounded-small"
								placeholder="Enter Post code"
							/>
						</v-col>
						<v-col cols="12" class="py-0">
							<label
								for="address"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Address
								<button
									v-if="
										Array.isArray(this.addresses) &&
										this.addresses.length > 0 &&
										this.invalidZipCode
									"
									class="ml-2 text-xs primary--text text-decoration-underline"
									@click="
										invalidZipCode = false
										user.address = ''
									"
								>
									Select from the list?
								</button>
							</label>
							<v-select
								v-if="!invalidZipCode"
								outlined
								id="address"
								:items="addresses"
								v-model="user.address"
								:rules="[requiredRule]"
								class="rounded-small"
								placeholder="Select Address"
								@change="handleAddressChange"
							>
							</v-select>
							<v-textarea
								v-else
								id="address"
								outlined
								:rules="[requiredRule]"
								v-model="user.address"
								class="rounded-small"
								:disabled="isDisabledAddress"
								placeholder="Enter Address"
							/>
						</v-col>
						<v-col cols="12" class="py-0 mb-16">
							<label
								for="extra-address-details"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Extra Address details</label
							>
							<v-textarea
								id="extra-address-details"
								outlined
								v-model="user.extraAddressInfo"
								class="rounded-small"
								placeholder="Enter extra address details"
							/>
						</v-col>
						<v-col cols="12" class="my-8">
							<v-btn
								type="submit"
								color="primary"
								class="pa-3 h-auto w-full text-capitalize d-block rounded-small"
								:disabled="isSubmitting"
							>
								<strong class="text-capitalize text-h6 font-weight-bold">Save</strong>
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</v-card>
	</div>
</template>

<script>
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import debounceFn from 'debounce-fn'
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import { getAddresses } from '@/services/address'
import { getUserProfileDetails, updateUserProfileDetails } from '@/services/profile'
import { requiredRule, emailRule, numberRule, mobileNoRule } from '@/validations'
import { SUCCESS, EXISTS } from '@/constants/status-code'

const Modal = () => import('@/components/Modal.vue')

export default {
	name: 'EditProfile',
	mixins: [AuthMixin],
	components: {
		Loader,
		Modal,
	},
	data() {
		return {
			requiredRule,
			emailRule,
			numberRule,
			mobileNoRule,
			isLoading: false,
			isSubmitting: false,
			isProfileUpdated: false,
			hasProfileUpdatingError: false,
			isDisabledAddress: false,
			invalidZipCode: false,
			profileUpdateErrorMessage: null,
			genderOptions: ['Male', 'Female', 'Other'],
			addresses: [],
			user: {
				firstName: '',
				lastName: '',
				emailId: '',
				gender: '',
				mobileNo: '',
				address: '',
				zipCode: '',
				extraAddressInfo: '',
			},
		}
	},
	created() {
		this.fetchUserProfileDetails()
	},
	mounted() {
		this.$watch(
			() => this.user.zipCode,
			debounceFn(
				() => {
					this.fetchAddressesList()
				},
				{ wait: 500 }
			)
		)
	},
	watch: {
		'user.zipCode': {
			handler() {
				// has address
				if (this.user.address) {
					// if address disabled then enable it
					if (this.isDisabledAddress) {
						this.isDisabledAddress = false
					}
					this.user.address = '' // clear address
				}
			},
		},
	},
	computed: {
		userFullName() {
			if (!this.userData) return ''
			const { firstName, lastName } = this.userData
			if (lastName) return `${firstName} ${lastName}`
			return firstName
		},
	},
	methods: {
		handleAddressChange(value) {
			if (value === 'Not in the List?') {
				this.isDisabledAddress = false
				this.user.address = ''
			} else {
				this.isDisabledAddress = true
			}
			this.invalidZipCode = true // show textarea
		},
		async fetchUserProfileDetails() {
			this.isLoading = true
			try {
				const res = await getUserProfileDetails(this.authToken)
				const { data, statusCode } = res.data
				if (statusCode === SUCCESS) {
					this.user.firstName = data.firstName
					this.user.lastName = data.lastName
					this.user.emailId = data.emailId
					this.user.gender = data.gender
					this.user.mobileNo = data.mobileNo
					this.user.zipCode = data.zipCode
					this.user.extraAddressInfo = data.extraAddressInfo
					this.$nextTick(() => {
						this.user.address = data.address
					})
				}
			} catch (e) {
				console.log(e)
			}
			this.isLoading = false
		},
		async handleUpdateUserProfile() {
			if (!this.$refs.updateProfileFormRef.validate()) return
			this.isSubmitting = true
			try {
				const res = await updateUserProfileDetails(this.authToken, { formData: this.user })
				const { statusCode, message } = res.data
				if (statusCode === SUCCESS) {
					this.isProfileUpdated = true
				}
				if (statusCode === EXISTS) {
					this.hasProfileUpdatingError = true
					this.profileUpdateErrorMessage = message
				}
			} catch (e) {
				console.log(e)
			}
			this.isSubmitting = false
		},
		async fetchAddressesList() {
			if (!this.user.zipCode) return

			try {
				const res = await getAddresses(this.authToken, { zipcode: this.user.zipCode.toUpperCase() })
				const { addresses } = res.data

				if (Array.isArray(addresses)) {
					// remove blank character ('') and join (, ) and trim address
					const addressList = addresses.map((address) => address.filter(String).join(', ').trim())
					this.addresses = [...addressList, 'Not in the List?']
					// if in address list then show select box else textarea
					this.invalidZipCode = !addressList.includes(this.user.address)
				}
			} catch (e) {
				const { response } = e
				if (response) {
					const { status } = response
					// status code 4xx - 5xx no data
					if (status >= 400) {
						this.addresses = []
						this.invalidZipCode = true
					}
				}
			}
		},
	},
}
</script>
